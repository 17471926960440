var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "alert", class: _vm.background }, [
    _c(
      "div",
      { staticClass: "alert-header" },
      [
        !_vm.to && _vm.title
          ? _c(
              "span",
              {
                staticClass:
                  "btn cursor-default text-muted px-3 text-uppercase strong",
                class: _vm.color
              },
              [
                _vm._icon
                  ? _c("i", { staticClass: "mr-2", class: _vm._icon })
                  : _vm._e(),
                _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.to && _vm.title
          ? _c(
              "router-link",
              {
                staticClass: "btn text-muted px-3 text-uppercase strong",
                class: _vm.color,
                attrs: { to: _vm.to }
              },
              [
                _vm._icon
                  ? _c("i", { staticClass: "mr-2", class: _vm._icon })
                  : _vm._e(),
                _vm._v("\n      " + _vm._s(_vm.title) + "\n    ")
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "btn text-muted p-0 ml-auto",
            class: _vm.color,
            on: { click: _vm.close }
          },
          [_c("i", { staticClass: "i-close" })]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "alert-body pt-0" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }